<template>
	<div class="bigscreen">
		<div class="bs_header">
			<div class="bs_title">冯墙巡防系统管理</div>
			<div class="backbtn" @click="goback" v-if="backbtnShow">
				<i class="el-icon-arrow-left"></i>返回
			</div>
		</div>
		<div class="bs_content">
			<div class="bs_left">
				<div class="base">
					<div class="smtit"><span>性别比例</span></div>
					<div class="smcont">
						<div id="sexRatioChartId" style="height: 100%"></div>
						<!-- <div class="personlegend" v-if="personLegend.length">
              <ul>
                <li v-for="(legend, index) in personLegend" :key="index">
                  <span></span>
                  <p>{{ `${legend.name}（${legend.value}人）` }}</p>
                </li>
              </ul>
            </div> -->
					</div>
				</div>
				<div class="inter">
					<div class="smtit"><span>人口分布</span></div>
					<div class="smcont">
						<div class="personfenbu">
							<div class="lft">
								<p><span>{{ personfenbu.huji + personfenbu.feihuji + personfenbu.zuke + personfenbu.huzairenbuzai }}</span>人
								</p>
								<div>总人数</div>
							</div>
							<div class="rit">
								<div>户在人在：<p><span>{{ personfenbu.huji }}</span>人</p>
								</div>
								<div>户在人不在：<p><span>{{ personfenbu.huzairenbuzai }}</span>人</p>
								</div>
								<div>户在人不经常在：<p><span>{{ personfenbu.feihuji }}</span>人</p>
								</div>
								<div>流动人口：<p><span>{{ personfenbu.zuke }}</span>人</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="online">
					<div class="smtit double"><span>小区流动人口前五排名</span></div>
					<div class="smcont right-chart-3-box">
						<div class="rank">
							<img src="@/assets/imgs/monitor/rank1.png" alt="">
							<img src="@/assets/imgs/monitor/rank2.png" alt="">
							<img src="@/assets/imgs/monitor/rank3.png" alt="">
							<img src="@/assets/imgs/monitor/rank4.png" alt="">
							<img src="@/assets/imgs/monitor/rank5.png" alt="">
						</div>
						<div class="namelist">
							<div v-for="(item,index) in rankFenbu" :key="index">{{item.regionname}}</div>
						</div>
						<div class="datalist">
							<div v-for="(item,index) in rankFenbu" :key="index"><span>{{item.nums}}</span>人</div>
						</div>
						<div class="chart-content" id="rank5"></div>
					</div>
				</div>
			</div>
			<div class="bs_center">
				<div class="xfinfo">
					<div class="smtit bigest"><span>巡防情况</span></div>
					<div class="smcont">
						<div class="xunfangsum">
							<div class="top">巡防总次数</div>
							<div class="bot">
								<div class="num" v-if="tasklognums"><span v-for="i in tasklognums"
										:key="i">{{ i }}</span>次</div>
								<div class="chart">
									<ul>
										<li>
											<span>周</span>
											<div>{{ xunfangqingkuang.zhouovernums }}</div>
										</li>
										<li>
											<span>月</span>
											<div>{{ xunfangqingkuang.yuueovernums }}</div>
										</li>
										<li>
											<span>季度</span>
											<div>{{ xunfangqingkuang.jiovernums }}</div>
										</li>
										<li>
											<span>年</span>
											<div>{{ xunfangqingkuang.nianovernums }}</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="xfnum">
					<div class="smtit bigest"><span>每日巡防次数/问题数</span></div>
					<div class="smcont" id="everyXfInfo" style="padding-left: 10px;">

					</div>
				</div>
				<div class="ques">
					<div class="smtit bigest"><span>问题占比数</span></div>
					<div class="smcont">
						<div id="questionOccupy" style="height: 100%;"></div>
					</div>
				</div>
			</div>
			<div class="bs_right">
				<div class="weather">
					<div class="smtit"><span>房屋情况</span></div>
					<div class="smcont">
						<div class="houseinfo">
							<div class="total sum">
								<p>总数</p>
								<div><span>{{ xiaoqunumsSum }}</span>个</div>
								<div><span>{{ xiaoquHouseSum }}</span>间</div>
							</div>
							<img class="jt" src="../../assets/imgs/monitor/house_info_icon2.png" alt="">
							<div class="item" v-for="xq in xiaoqunums" :key="xq.name">
								<img class="info" src="../../assets/imgs/monitor/house_info_icon3.png" alt="">
								<div class="total">
									<p>{{ xq.name }}数</p>
									<div><span>{{ xq.value }}</span> 个</div>
									<div><span>{{ xq.housenums }}</span>间</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="fuhe">
					<div class="smtit"><span>房屋类型数</span></div>
					<div class="smcont">
						<div id="houseTypeCont" style="height: 100%; padding-left: 5px;"></div>
					</div>
				</div>
				<div class="custom">
					<div class="smtit"><span>走访记录</span></div>
					<div class="smcont">
						<div class="zoufanglist">
							<div>
								<vueSeamlessScroll :data="zoufangList" class="seamless-warp"
									:class-option="defaultOption">
									<ul>
										<li v-for="(item,index) in zoufangList" :key="index">
											<span>{{ item.manname }}</span>
											<span v-if="item.dologtypename"> {{ item.dologtypename }}</span>
											<span v-if="item.dologdesc"> {{ item.dologdesc }}</span>
										</li>
									</ul>
								</vueSeamlessScroll>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import vueSeamlessScroll from "vue-seamless-scroll";
	import {
		dapingData
	} from '@/api/login'
	export default {
		name: "bigscreen",
		components: {
			vueSeamlessScroll
		},
		props: {
			backbtnShow: {
				default: true,
				type: Boolean,
			},
		},
		data() {
			return {
				sexRatioChartDom: null,
				rank5ChartDom: null,
				everyXfInfoDom: null,
				houseTypeDom: null,
				questionOccupyDom: null,
				personinfo: [],
				personAge: [],
				personfenbu: {},
				rankFenbu: [],
				wentilist: [],
				houosetypelist: [],
				xiaoqunums: [],
				xiaoqunumsSum: 0,
				xiaoquHouseSum: 0,
				zoufangList: [],
				xunfangqingkuang: {},
				tasklognums: '0',
				defaultOption: {
					step: 0.4
				}
			};
		},
		watch: {},
		created() {},
		mounted() {

			this.getData();
		},
		beforeDestroy() {},
		computed: {},
		methods: {
			getData() {
				dapingData({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId()
				}).then((res) => {
					if (res.result == '200') {
						// console.log(res)
						//性别比例
						this.personinfo = res.detail.personinfo;
						this.personAge = res.detail.agelist;
						this.sexRatioChart();
						//人口分布
						this.personfenbu = res.detail.personfenbu;
						//小区流动人口前五排名
						this.rankFenbu = res.detail.zukefenbu.fenbu;
						this.rank5Chart();
						//问题占比数
						this.wentilist = res.detail.wentilist;
						this.questionOccupyChart();
						//走访记录
						this.zoufangList = res.detail.appmanlist;
						//房屋类型数
						this.houosetypelist = res.detail.housetype;
						this.houseTypeChart();
						//房屋情况
						this.xiaoqunums = res.detail.xiaoqunums;
						this.xiaoqunums.map(item => {
							this.xiaoqunumsSum += item.value;
							this.xiaoquHouseSum += item.housenums;
						})
						//每日巡防次数/问题数
						this.meiriData = res.detail.meiri;
						this.everyXfInfoChart();
						//巡防情况
						this.xunfangqingkuang = res.detail.xunfangqingkuang;
						this.tasklognums = this.xunfangqingkuang.tasklognums + '';
					} else {
						this.$message.error(res.description);
					}
				})
			},
			//返回
			goback() {
				this.$router.go(-1);
			},
			questionOccupyChart() {
				if (
					this.questionOccupyDom != null &&
					this.questionOccupyDom != "" &&
					this.questionOccupyDom != undefined
				) {
					this.questionOccupyDom.dispose();
				}

				this.questionOccupyDom = this.$echarts.init(
					document.getElementById("questionOccupy")
				);


				let questionData = [];
				this.wentilist.map(item => {
					questionData.push(item.value);
				})

				// 初始化最大值为第一个对象的属性值
				let maxValue = questionData[0];

				// 遍历数组，比较每个对象的属性值
				for (let i = 1; i < questionData.length; i++) {
					if (questionData[i] > maxValue) {
						maxValue = questionData[i];
					}
				}
				// console.log(maxValue)
				// console.log(questionData)

				this.wentilist.map(item => {
					item.max = maxValue + 1
				})

				// console.log(this.wentilist)


				let option = {
					tooltip: {
						trigger: 'item',
					},
					radar: {
						indicator: this.wentilist,
						radius: 80,
						startAngle: 90,
						splitNumber: 3,
						shape: 'circle',
						axisName: {
							formatter: '{value}',
							color: '#dae2ff'
						},
						splitArea: {
							areaStyle: {
								color: ['#003b4b', '#01536c', '#003b4b', '#01536c'],
								shadowColor: 'rgba(0, 0, 0, 0.2)',
								shadowBlur: 10
							}
						},
						axisLine: {
							lineStyle: {
								color: 'rgba(211, 253, 250, 0)'
							}
						},
						splitLine: {
							lineStyle: {
								color: 'rgba(211, 253, 250, 0)'
							}
						}
					},
					series: [{
						color: ['#6cedbd'],
						type: 'radar',
						areaStyle: {
							color: 'rgba(255, 228, 52, 0.6)'
						},
						tooltip: {
							trigger: 'item'
						},
						data: [{
							value: questionData,
							name: '问题占比数'
						}]
					}]
				};
				option && this.questionOccupyDom.setOption(option);
			},
			houseTypeChart() {
				if (
					this.houseTypeDom != null &&
					this.houseTypeDom != "" &&
					this.houseTypeDom != undefined
				) {
					this.houseTypeDom.dispose();
				}

				this.houseTypeDom = this.$echarts.init(
					document.getElementById("houseTypeCont")
				);

				let xAxisData = [];
				let yAxisData = [];
				this.houosetypelist.map(item => {
					xAxisData.push(item.name);
					yAxisData.push(item.value)
				})

				let yAxisMaxData = [];
				let yAxisMax = 0;
				yAxisData.map(val => {
					if (val > yAxisMax) {
						yAxisMax = val;
					}
				})
				yAxisMax = yAxisMax / 4 * 5;
				yAxisData.map((index, val) => {
					yAxisMaxData.push(yAxisMax);
				})

				let option = {
					title: [{
						subtext: '单位：户',
						subtextStyle: {
							fontSize: 12,
							color: '#bdc7fd'
						}
					}],
					tooltip: {
						trigger: 'axis'
					},
					color: ['#0071eb'],
					grid: {
						top: '20%',
						left: '20',
						right: '20',
						bottom: '15',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: xAxisData,
						axisLabel: {
							color: '#bdc7fd',
							interval: 0,
							fontSize: 10
						},
						axisLine: {
							color: '#2a4776',
							lineStyle: {
								color: '#2a4776'
							}
						}
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							color: '#bdc7fd'
						},
						splitLine: {
							lineStyle: {
								color: '#2a4776'
							}
						},
						axisLine: {
							lineStyle: {
								color: '#2a4776'
							}
						}
					},
					series: [{
							type: 'pictorialBar',
							itemStyle: {
								normal: {
									color: '#0071eb'
								}
							},
							symbolRepeat: 'fixed',
							symbolMargin: 1.2,
							symbol: 'rect',
							symbolClip: true,
							symbolSize: [30, 3],
							symbolPosition: 'start',
							symbolOffset: [0, 0],
							z: 4,
							showSymbol: false,
							smooth: true,
							barWidth: 30,
							data: yAxisData
						},
						{
							name: '背景',
							type: 'pictorialBar',
							itemStyle: {
								normal: {
									color: '#172c7c'
								}
							},
							symbolRepeat: 'fixed',
							symbolMargin: 1.2,
							symbol: 'rect',
							symbolClip: true,
							symbolSize: [30, 3],
							symbolPosition: 'start',
							symbolOffset: [1, 0],
							z: 0,
							tooltip: {
								show: false
							},
							data: yAxisMaxData
						}
					]
				};
				option && this.houseTypeDom.setOption(option);
			},
			everyXfInfoChart() {
				if (
					this.everyXfInfoDom != null &&
					this.everyXfInfoDom != "" &&
					this.everyXfInfoDom != undefined
				) {
					this.rank5CheveryXfInfoDomartDom.dispose();
				}

				this.everyXfInfoDom = this.$echarts.init(
					document.getElementById("everyXfInfo")
				);

				let taskNums = [];
				let problemNums = [];
				let dates = [];
				this.meiriData.daytaskloglist.map(item => {
					taskNums.push(item.nums);
					dates.push(item.createday)
				})
				this.meiriData.daytaskquestionlog.map(item => {
					problemNums.push(item.nums)
				})


				let option = {
					title: {
						subtext: '单位：次',
						subtextStyle: {
							fontSize: 12,
							color: '#dae2ff'
						}
					},
					legend: {
						right: '20',
						top: '15',
						orient: "horizontal",
						data: ['巡防次数', '问题数'],
						icon: 'roundRect',
						textStyle: {
							color: '#e2f0ff',
							fontSize: 12
						}
					},
					grid: {
						top: '20%',
						left: '20',
						right: '20',
						bottom: '20',
						containLabel: true
					},
					tooltip: {
						trigger: 'axis'
					},
					color: ['#59a9fc', '#eb5353'],
					xAxis: {
						type: 'category',
						// boundaryGap: false,
						data: dates,
						axisLabel: {
							color: '#bdc7fd'
						},
						axisTick: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: '#2a4776'
							}
						},
						axisLine: {
							lineStyle: {
								color: '#2a4776'
							}
						}
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							color: '#bdc7fd'
						},
						splitLine: {
							lineStyle: {
								color: '#2a4776'
							}
						},
						axisLine: {
							lineStyle: {
								color: '#2a4776'
							}
						}
					},
					series: [{
							name: '巡防次数',
							data: taskNums,
							type: 'line',
							areaStyle: {
								color: new this.$echarts.graphic.LinearGradient(
									0, 0, 0, 1,
									[{
											offset: 0,
											color: '#59a9fc'
										},
										{
											offset: 1,
											color: 'rgba(89, 169 , 252, 0)'
										}
									]
								)
							},
							showSymbol: false,
							// 	smooth: true,
						},
						{
							name: '问题数',
							data: problemNums,
							type: 'line',
							lineStyle: {
								color: '#eb5353'
							},
							showSymbol: false
						}
					]
				};
				option && this.everyXfInfoDom.setOption(option);
			},
			rank5Chart() {
				if (
					this.rank5ChartDom != null &&
					this.rank5ChartDom != "" &&
					this.rank5ChartDom != undefined
				) {
					this.rank5ChartDom.dispose();
				}

				this.rank5ChartDom = this.$echarts.init(
					document.getElementById("rank5")
				);

				let xdata = [];
				let ydata = [];
				let maxData = [];


				this.rankFenbu.map(item => {
					xdata.push(item.regionname);
					ydata.push(item.nums);
					maxData.push(item.nums);
				})

				//排名不足5个，用0补全
				if (ydata.length < 5) {
					for (let i = ydata.length; i < 5; i++) {
						ydata[i] = 0;
						xdata[i] = ''
					}
				}


				maxData.sort((a, b) => {
					return b - a
				})
				//设置最大值+3作为背景
				let maxdata = [];
				maxData.map(() => {
					maxdata.push(maxData[0])
				})

				let option = {
					textStyle: {
						color: '#2C3E50'
					},
					tooltip: {
						trigger: 'item',
					},
					grid: {
						top: '8.1%',
						left: '0',
						right: '0',
						bottom: '3.74%',
					},
					yAxis: [{
						type: 'category',
						data: xdata,
						show: false,
						axisLabel: {
							color: '#8C8C8C',
							fontSize: 12
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#eee'
							}
						},
						inverse: true
					}],
					xAxis: [{
						type: 'value',
						axisLabel: {
							color: '#8C8C8C',
							fontSize: 12
						},
						show: false,
						axisTick: {
							show: false
						},
						splitLine: {
							show: false,
						},
						axisLine: {
							show: false
						}
					}],
					series: [{
							name: '小区流动人口前五排名',
							type: 'bar',
							barWidth: 13,
							itemStyle: { // 图形样式
								// ECharts.graphic.LinearGradient(ECharts内置的渐变色生成器)
								// 4个参数用于配置渐变色的起止位置,这4个参数依次对应右 下 左 上
								color: (params) => {
									var colorList1 = ['#00fef6', '#00fef6', '#00fff6', '#00fff6', '#ffb400'];
									var colorList2 = ['#0d80e1', '#0d80e1', '#0d80e1', '#0d80e1', '#de9700'];
									return new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
										offset: 0,
										color: colorList1[params.dataIndex % colorList1.length]
									}, {
										offset: 1,
										color: colorList2[params.dataIndex % colorList2.length]
									}], false);
								}
							},
							data: ydata
						},
						{
							// 值分隔
							name: '电池',
							type: 'pictorialBar',
							itemStyle: {
								normal: {
									color: '#060f4a'
								}
							},
							symbolRepeat: 'fixed',
							symbolMargin: 1.2,
							symbol: 'rect',
							symbolClip: true,
							symbolSize: [3, 13],
							symbolPosition: 'start',
							symbolOffset: [3, 0],
							z: 3,
							tooltip: {
								show: false
							},
							data: maxdata
						},
						{
							name: '背景',
							type: 'bar',
							barWidth: 13,
							barGap: '-100%',
							data: maxdata,
							z: 0,
							tooltip: {
								show: false
							},
							itemStyle: {
								normal: {
									color: '#2a399c'
								}
							}
						}
					]
				};
				option && this.rank5ChartDom.setOption(option);
			},
			//性别比例
			sexRatioChart() {

				if (
					this.sexRatioChartDom != null &&
					this.sexRatioChartDom != "" &&
					this.sexRatioChartDom != undefined
				) {
					this.sexRatioChartDom.dispose();
				}

				this.sexRatioChartDom = this.$echarts.init(
					document.getElementById("sexRatioChartId")
				);


				let option = {
					tooltip: {
						trigger: "item",
						formatter: "{a} <br/>{b}: {c} ({d}%)",
					},
					legend: {
						orient: "vertical",
						right: "15%",
						top: "center",
						// data: legendArr,
						textStyle: {
							color: "#fff",
						},
					},
					series: [{
							name: "性别比例",
							type: "pie",
							left: "-30%",
							radius: [0, "40%"],
							label: {
								position: "inner",
								fontSize: 14,
								textStyle: {
									color: "#fff",
								},
							},
							labelLine: {
								show: false,
							},
							data: this.personinfo,
							color: ["#f97c44", "#3b85e1"],
						},
						{
							name: "年龄比例",
							type: "pie",
							left: "-30%",
							radius: ["60%", "75%"],
							label: {
								show: false,
							},
							labelLine: {
								show: false,
							},
							data: this.personAge,
							color: [
								"#00d7e9",
								"#70cbf0",
								"#f6bd15",
								"#5b8ff9",
								"#1a9c69",
								"#706dd3",
							],
						},
					],
				};
				option && this.sexRatioChartDom.setOption(option);
			},
		},
	};
</script>

<style lang="scss" scoped>
	@font-face {
		font-family: 'agency-bold';
		src: url('../../assets/font/agency-bold.ttf');
	}


	.bigscreen {
		background: url("../../assets/imgs/monitor/bg.jpg") no-repeat;
		background-size: 100% 100%;
		height: 1080px;
		width: 1920px;
		box-sizing: border-box;

		.bs_header {
			height: 80px;
			line-height: 78px;
			position: relative;

			.bs_title {
				position: absolute;
				left: 120px;
				top: 0;
				width: 493px;
				height: auto;
				font-size: 32px;
				color: #d2ecff;
				font-family: "微软雅黑", -apple-system, BlinkMacSystemFont, "Segoe UI",
					Roboto, "Helvetica Neue", Arial, "黑体", "Noto Sans", sans-serif,
					"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
					"Noto Color Emoji";
				font-weight: bold;
			}

			.backbtn {
				position: absolute;
				left: 30px;
				line-height: 70px;
				top: 0;
				color: #87d7f5;
				cursor: pointer;
				transition: 0.3s all;

				&:hover {
					text-decoration: underline;
					color: #00f5db;
				}
			}
		}

		.smtit {
			width: 100%;
			background: url("../../assets/imgs/monitor/sm_tit_bg.png") no-repeat;
			background-size: 100% auto;
			padding-left: 10px;
			line-height: 41px;
			font-size: 18px;
			font-family: "微软雅黑", -apple-system, BlinkMacSystemFont, "Segoe UI",
				Roboto, "Helvetica Neue", Arial, "黑体", "Noto Sans", sans-serif,
				"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
				"Noto Color Emoji";
			font-weight: bold;
			margin-bottom: 6px;
			box-sizing: border-box;

			&.double {
				background: url("../../assets/imgs/monitor/sm_tit_bg_big.png") no-repeat;
				background-size: 100% auto;
			}

			&.bigest {
				background: url("../../assets/imgs/monitor/sm_tit_bg_bigest.png") no-repeat;
				background-size: 100% auto;
			}

			span {
				background: linear-gradient(to bottom, #fff, #5acfe4);
				background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		.smcont {
			height: calc(100% - 41px - 6px);
			background: rgba(28, 132, 213, 0.1);
			position: relative;

			&::before,
			&::after {
				content: "";
				position: absolute;
				width: 10px;
				bottom: -1px;
				height: 10px;
				background: url(../../assets/imgs/monitor/jiao_icon.png) no-repeat;
				background-size: cover;
				opacity: 0.8;
			}

			&::before {
				left: -1px;
				transform: rotate(90deg);
			}

			&::after {
				right: -1px;
			}

			.personlegend {
				position: absolute;
				z-index: 10;
				color: #fff;
				right: 20px;
				top: 0;
				bottom: 0;
				display: flex;
				font-size: 14px;
				align-items: center;

				li {
					display: flex;
					align-items: center;
				}
			}
		}

		.bs_content {
			height: calc(100% - 80px - 25px);
			padding: 20px 45px;
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			position: relative;
		}

		.bs_left {
			width: 470px;
			height: 100%;

			.base {
				height: 285px;
				margin-bottom: 14px;

				.cont {
					height: 239px;
					display: flex;
					background-color: rgba(28, 132, 213, 0.1);
					padding-left: 18px;

					ul {
						width: 100%;
						justify-content: space-between;
						display: flex;
						flex-flow: row wrap;
						height: 92%;
					}

					li {
						width: 50%;
						height: 50%;
						display: flex;
						align-items: center;

						.text {
							margin-left: 18px;
							color: #dae2ff;
						}

						.num {
							margin-top: 3px;

							span {
								font-size: 24px;
								font-weight: bold;
								background: linear-gradient(to bottom, #fff, #55e8ff);
								background-clip: text;
								-webkit-text-fill-color: transparent;
								margin-right: 2px;
							}
						}

						.double {
							.text {
								line-height: 1em;

								&+.text {
									margin-top: 10px;
								}
							}
						}
					}
				}
			}

			.inter {
				height: 335px;
				margin-bottom: 14px;

				.personfenbu {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;

					.lft {
						width: 172px;
						height: 152px;
						background: url(../../assets/imgs/monitor/fenbubg.png) no-repeat;
						color: #fff;
						text-align: center;
						box-sizing: border-box;
						padding-top: 20px;

						p {
							color: #a2eaff;
						}

						span {
							font-size: 24px;
							font-weight: bold;
						}
					}

					.rit {
						margin-left: 25px;

						>div {
							width: 220px;
							height: 40px;
							line-height: 40px;
							padding-left: 15px;
							border-left: 1px solid #1d98c0;
							background-image: linear-gradient(to right, #134f76, transparent);
							color: #a4d6f6;
							font-size: 16px;
							display: flex;
							margin-bottom: 10px;

							p {
								color: #fff;
							}

							span {
								font-size: 20px;
							}
						}
					}
				}
			}

			.online {
				height: 285px;

				.right-chart-3-box {
					position: relative;

					.chart-content {
						position: relative;
						height: 100%;
						width: calc(100% - 120px);
						z-index: 10;
						left: 46px;
					}

					.rank {
						position: absolute;
						left: 8px;
						top: 16px;

						img {
							display: block;
							width: 32px;
							height: auto;
							margin-bottom: 10px;
						}
					}

					.namelist {
						position: absolute;
						left: 46px;
						top: 10px;

						>div {
							line-height: 1.6em;
							color: #4fbdbd;
							font-size: 14px;
							margin-bottom: 20px;
						}
					}

					.datalist {
						position: absolute;
						right: 15px;
						top: 24px;

						>div {
							font-size: 12px;
							color: #4fbdbd;
							margin-bottom: 3px;

							span {
								font-family: 'agency-bold';
								font-weight: bold;
								font-size: 20px;
								line-height: 1.9em;
							}
						}
					}
				}

			}
		}

		.bs_right {
			width: 470px;
			height: 100%;

			.weather {
				height: 252px;
				margin-bottom: 14px;

				.houseinfo {
					display: flex;
					height: 100%;
					padding: 0 20px;
					justify-content: space-evenly;
					align-items: center;
					color: #d3e1ff;

					.jt {
						width: 25px;
						height: auto;
					}

					.item {
						display: flex;
						align-items: center;
						justify-content: space-evenly;
					}

					.info {
						width: 64px;
						height: 64px;
					}

					.sum {
						margin-top: 25px;
						padding-bottom: 40px;
						background: url(../../assets/imgs/monitor/house_info_icon1.png) no-repeat 15% bottom;
						width: 120px;
						text-align: center;
					}

					.total {
						display: flex;
						flex-direction: column;

						p {
							font-size: 14px;
						}

						div {
							font-size: 14px;

							span {
								font-size: 22px;
								font-family: 'agency-bold';
								letter-spacing: 2px;
							}
						}
					}
				}
			}

			.fuhe {
				height: 265px;
				margin-bottom: 14px;

				.cont {
					// height: calc(100% - 46px);
					height: 279px;
					background-color: rgba(28, 132, 213, 0.1);

					>div {
						height: 100%;
					}
				}
			}

			.custom {
				height: 388px;

				.zoufanglist {
					height: 100%;
					padding: 16px;
					box-sizing: border-box;

					>div {
						height: 100%;
						overflow: hidden;
					}

					li {
						line-height: 1.2em;
						background: #18314f;
						font-size: 16px;
						color: #d2ecff;
						padding: 8px 10px;
						margin-bottom: 10px;
					}
				}
			}
		}

		.bs_center {
			width: 848px;
			height: 100%;

			.xfinfo {
				height: 285px;
				margin-bottom: 14px;

				.xunfangsum {
					color: #dfeef3;
					padding: 30px;

					.top {
						font-size: 16px;
					}

					.bot {
						display: flex;
						justify-content: space-between;

						.num {
							display: flex;
							align-items: center;
							margin-top: 20px;
							min-width: 270px;
							justify-content: center;

							span {
								font-size: 40px;
								font-family: 'agency-bold';
								padding: 30px 10px;
								border: 1px solid #18416b;
								background: #081f38;
								margin-right: 10px;
							}
						}

						ul {
							display: flex;

							li {
								width: 98px;
								text-align: center;
								margin-right: 30px;

								&:last-child {
									margin-right: 0;
								}

								>div {
									margin-top: 10px;
									height: 98px;
									display: flex;
									justify-content: center;
									align-items: center;
									background: url(../../assets/imgs/monitor/circlebg.png) no-repeat;
									background-size: 100% auto;
								}
							}
						}
					}
				}
			}

			.xfnum {
				height: 335px;
				margin-bottom: 14px;
			}

			.ques {
				height: 285px;
			}

			.cont {
				height: 890px;
				background-color: rgba(28, 132, 213, 0.1);
				padding-top: 50px;
				position: relative;
			}
		}
	}
</style>